<template>
  <div>
    <div v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader" style="height: 300px;"></div>
    <el-form v-if="!loading" id="addReview" size="mini" v-loading="addingProject" element-loading-spinner="atom-audit-loader" label-position="left" label-width="150px"
      :model="localAddReview" :rules="rules" ref="addReview">
      <el-row>
        <el-col>
          <el-form-item size="mini" label="Project Name" prop="projectID">
            <el-input size="mini" id="projectID" placeholder="Project Name" maxlength="200" v-model="localAddReview.projectID"> </el-input>
          </el-form-item>
          <el-form-item size="mini" label="Project Type" prop="projectTypeId">
            <hia-el-select v-model="localAddReview.projectTypeId" placeholder="Project Type" :clearable="false" :filterable="true" itemkey="key" label="value"
              :dropdowns="dropdowns.projectTypes.list"></hia-el-select>
          </el-form-item>
          <!-- <el-form-item v-if="betaFeatures() && isOutpatient && isTrucodeEnabled()" size="mini" label="Outpatient Grouper" prop="outpatientGrouperId">
            <hia-el-select v-model="localAddReview.outpatientGrouperId" placeholder="Outpatient Grouper" :clearable="false" :filterable="true" itemkey="key" label="value"
              :dropdowns="dropdowns.outpatientGroupers.list"></hia-el-select>
          </el-form-item> -->
          <el-form-item size="mini" label="Start Date" prop="startDate">
            <el-date-picker size="mini" type="date" v-model="localAddReview.startDate" id="reviewStartDate" format="MM/dd/yyyy"></el-date-picker>
          </el-form-item>
          <el-form-item size="mini" label="End Date" prop="endDate">
            <el-date-picker size="mini" type="date" v-model="localAddReview.endDate" id="reviewendDate" format="MM/dd/yyyy"></el-date-picker>
          </el-form-item>
          <el-form-item prop="name" label="Process">
            <hia-el-select v-model="localAddReview.processId" placeholder="Process" clearable :filterable="true" itemkey="key" label="value"
              :dropdowns="dropdowns.projectProcesses.list"></hia-el-select>
          </el-form-item>
          <el-form-item size="mini" label="Lead" prop="leadId">
            <hia-el-select v-model="localAddReview.leadId" placeholder="Lead" :clearable="false" :filterable="true" itemkey="key" label="value"
              :dropdowns="dropdowns.consultants.list"></hia-el-select>
          </el-form-item>
          <el-form-item size="mini" label="Default Facility" prop="clientId">
            <hia-el-select v-model="localAddReview.clientId" placeholder="Facility" :clearable="false" :filterable="true" itemkey="key" label="value"
              :dropdowns="dropdowns.clients.list"></hia-el-select>
          </el-form-item>
          <el-form-item v-if="[1002, 1004, 1003].includes(localAddReview.projectTypeId)" size="mini" label="Default Code Type">
            <hia-el-select v-model="localAddReview.defaultCodeType" placeholder="Code Type" itemkey="key" label="value" :dropdowns="dropdowns.codeTypes.list" clearable
              filterable> </hia-el-select>
          </el-form-item>

          <el-form-item label="Project Tags" prop="identifierID">
            <el-select size="mini" v-model="localAddReview.addIdentifierSelectionsArray" filterable multiple placeholder="Tags">
              <el-option v-for="item in dropdowns.projectIdentifiers.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="mini" label="Description">
            <textarea style="width: 100%;" class="el-textarea__inner" type="textarea" :rows="2" id="comments" placeholder="Description"
              v-model="localAddReview.description"></textarea>
          </el-form-item>
          <br />
          <br />
          <el-alert v-if="errorMessage" style="margin-top: -30px; margin-bottom: 20px;" :closable="false" type="warning" title="" show-icon center>
            {{ errorMessage }}
          </el-alert>
          <el-button-group style="float: right;">
            <el-button class="hiaButton" plain round size="mini" type="text" @click="cancelAddProject()">Cancel</el-button>
            <el-button class="hiaButton" plain round size="mini" @click="addProject()">Add Project</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex'
  import HiaAuthorization from '@/mixins/hiaAuthorization'

  export default {
    name: 'addProject',
    mixins: [HiaAuthorization],
    data() {
      return {
        loading: true,
        addingProject: false,
        errorMessage: '',
        localAddReview: {},
        rules: {
          projectTypeId: [{ required: true, message: 'Project Type is required', trigger: 'none' }],
          outpatientGrouperId: [{ required: true, message: 'Project Type is required', trigger: 'none' }],
          // clientId: [
          //   { required: true, message: 'Facility is required', trigger: 'none' }
          // ],
          startDate: [{ required: true, message: 'Start Date is required', trigger: 'none' }],
          // endDate: [
          //   { required: true, message: 'End Date is required', trigger: 'none' }
          // ],
          projectID: [{ required: true, message: 'Project Name is required', trigger: 'none' }]
          // serviceLineID: [
          //   { required: true, message: 'Service Line is required', trigger: 'change' }
          // ]
        },
        dropdownsNeeded: ['clients', 'projectIdentifiers', 'projectTypes', 'coders', 'consultants', 'outpatientGroupers', 'projectProcesses']
      }
    },
    mounted: function () {
      this.GET_DROPDOWNS(this.dropdownsNeeded).then(() => {
        this.loading = false
      })
      this.localAddReview = Object.assign({}, this.newprojectDefaultValues) // otherwise, just passing a pointer to vuex, and get into messy binding issues
    },
    methods: {
      ...mapActions('managedLists/projects/', ['ADD_PROJECT']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS']),
      ...mapMutations('managedLists/projects/', ['SET_SHOW_ADD_PROJECT_FORM']),
      addProject() {
        this.$refs.addReview.validate(async valid => {
          if (valid) {
            this.addingProject = true
            this.localAddReview.outpatientGrouperId = this.isAsc ? 2 : 1
            this.localAddReview.projectColor = '#003169'
            this.localAddReview.projectStatusId = 1
            try {
              await this.ADD_PROJECT(this.localAddReview)
              this.addingProject = false
              this.clearForm()
              this.SET_SHOW_ADD_PROJECT_FORM(false)
              this.$message({
                message: 'Project Added Successfully',
                type: 'success'
              })
              this.$router.push(`/projects/${this.newProjectID}`)
            } catch (err) {
              this.addingProject = false
              this.errorMessage = err.message
            }
          }
        })
      },
      cancelAddProject() {
        this.$refs.addReview.clearValidate()
        this.SET_SHOW_ADD_PROJECT_FORM(false)
      },
      clearForm() {
        this.$refs.addReview.clearValidate()
        this.$refs.addReview.resetFields()
        this.errorMessage = null
        this.localAddReview = Object.assign({}, this.newprojectDefaultValues)
      },
      randomHexColor() {
        return `#${Math.floor(Math.random() * 16777215)
          .toString(16)
          .trim()}`
      }
    },
    computed: {
      ...mapState('managedLists/projects/', ['newProjectID']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/projects/', ['newprojectDefaultValues']),
      isAsc() {
        if (this.localAddReview && this.dropdowns && this.dropdowns.projectTypes) {
          return this.dropdowns.projectTypes.list.some(x => x.value === 'Ambulatory' && x.key === this.localAddReview.projectTypeId)
        }
        return false
      }
    }
  }
</script>

<style scoped>
  #addReview textarea::placeholder {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }
</style>