<template>
  <div id="dialogs">
    <el-dialog :visible="showMassUpdateDialog" width="50%" :before-close="handleClose">
      <template slot="title">
        <template v-if="selectedRecords.length > 0">
          <div class="el-dialog__title">Mass Update {{selectedRecords.length}} Record{{selectedRecords.length > 1 ? 's' : ''}}</div>
          <small v-if="!loading" style="font-style: italic; display: inline-block;">Select the toggles to update individual fields</small>
        </template>
        <template v-else>
          No Records Selected
        </template>
      </template>
      <div v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader" style="height: 390px; margin-top: -50px;"></div>

      <el-form v-loading="runningMassUpdate" element-loading-spinner="atom-audit-loader" label-position="left" size="mini" label-width="120px"
        :model="massUpdate.proRecordMassUpdateFields" ref="massUpdate" v-if="selectedRecords.length > 0 && !loading">
        <template>
          <el-row>
            <el-row>
              <el-row>
                <el-col :span="3">
                  <el-switch v-model="massUpdate.updateProject" active-color="#13ce66" inactive-color="#ff4949">
                  </el-switch>
                </el-col>
                <el-col :span="18">
                  <el-form-item size="mini" ref="dateFormItem" label="Project Name" prop="projectID">
                    <projectSelector v-model="massUpdate.proRecordMassUpdateFields.projectID" :disabled="!massUpdate.updateProject" :project="selectedProject"
                      @selected="selectedProject = $event" :defaultQuery="{ projectTypeId: project.projectTypeId }"></projectSelector>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateClientNumber" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </el-col>
              <el-col :span="18">
                <el-form-item ref="clientNumberFormItem" label="Facility" prop="clientNumber">
                  <hia-el-select :disabled="!massUpdate.updateClientNumber" v-model="massUpdate.proRecordMassUpdateFields.clientId" placeholder="Facility" :filterable="true"
                    itemkey="key" label="value" clearable :dropdowns="dropdowns.clients.list">
                  </hia-el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateReviewDate" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </el-col>
              <el-col :span="18">
                <el-form-item ref="dateFormItem" label="Review Date" prop="date">
                  <el-date-picker :disabled="!massUpdate.updateReviewDate" size="mini" type="date" v-model="massUpdate.proRecordMassUpdateFields.reviewDate" id="date"
                    format="MM/dd/yyyy" placeholder="Review Date"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateProvider" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </el-col>
              <el-col :span="18">
                <el-form-item size="mini" ref="providerFormItem" label="Provider">
                  <providerSelector :disabled="!massUpdate.updateProvider" v-model="massUpdate.proRecordMassUpdateFields.providerId" @selected="clientProvider = $event"
                    :provider="clientProvider">
                  </providerSelector>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateConsultantNumber" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </el-col>
              <el-col :span="18">
                <el-form-item size="mini" ref="consultantNumberFormItem" label="Auditor">
                  <hia-el-select :disabled="!massUpdate.updateConsultantNumber" v-model="massUpdate.proRecordMassUpdateFields.consultantNumber" placeholder="Auditor"
                    :clearable="true" :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.consultants.list">
                  </hia-el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateCoder" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              </el-col>
              <el-col :span="18">
                <el-form-item size="mini" label="Coder">
                  <el-col :span="24" style="padding-left: 0px; padding-right: 0px;">
                    <coderSelector :disabled="!massUpdate.updateCoder" v-model="coderComputed" @selected="clientCoder = $event" :clientcoder="clientCoder">
                    </coderSelector>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updatePayor" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              </el-col>
              <el-col :span="18">
                <el-form-item ref="payorNumberFormItem" size="mini" label="Payor">
                  <hia-el-select :disabled="!massUpdate.updatePayor" v-model="massUpdate.proRecordMassUpdateFields.payorNumber" placeholder="Payor" :clearable="true"
                    :filterable="true" itemkey="key" label="value" :dropdowns="dropdowns.payors.list">
                  </hia-el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateStatus" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              </el-col>
              <el-col :span="18">
                <el-form-item ref="statusFormItem" size="mini" label="Review Status">
                  <el-select :disabled="!massUpdate.updateStatus" :default-first-option="true" size="mini" v-model="massUpdate.proRecordMassUpdateFields.reviewStatusId"
                    placeholder="Review Status" clearable filterable>
                    <el-option v-for="item in dropdowns.reviewStatus.list" :key="item.key" :label="item.value" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateRecordTags" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </el-col>
              <el-col :span="13">
                <el-form-item size="mini" ref="recordTagsFormItem" label="Record Tags">
                  <el-select class="multiSelect" :disabled="!massUpdate.updateRecordTags" size="mini" v-model="massUpdate.proRecordMassUpdateFields.tagsSelections" filterable
                    multiple placeholder="Tags">
                    <el-option v-for="item in dropdowns.recordTags.list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.inactive">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" style="margin-left: 10px;">
                <el-row>
                  <input :disabled="!massUpdate.updateRecordTags" id="overwriteTagsFalse" type="radio" name="overwrite" value="false" v-model="massUpdate.overwriteTags">
                  Append Tags
                </el-row>
                <el-row>
                  <input :disabled="!massUpdate.updateRecordTags" id="overwriteTagsTrue" type="radio" name="overwrite" value="true" v-model="massUpdate.overwriteTags">
                  Overwrite
                  Tags
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="3">
                <el-switch v-model="massUpdate.updateComments" active-color="#13ce66" inactive-color="#ff4949">
                </el-switch>
              </el-col>
              <el-col :span="13">
                <el-form-item label="Comments">
                  <el-input :disabled="!massUpdate.updateComments" type="textarea" :rows="3" id="comments" v-model="massUpdate.proRecordMassUpdateFields.comments"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" style="margin-left: 10px;">
                <el-row>
                  <input :disabled="!massUpdate.updateComments" id="icd9" type="radio" name="overwriteComments" value="false" v-model="massUpdate.overwriteComments"> Append
                  Comments
                </el-row>
                <el-row>
                  <input :disabled="!massUpdate.updateComments" id="icd10" type="radio" name="overwriteComments" value="true" v-model="massUpdate.overwriteComments">
                  Overwrite Comments
                </el-row>
              </el-col>
            </el-row>
          </el-row>
          <br />
        </template>

      </el-form>
      <el-button-group style="float: right;">
        <el-button class="hiaButton" plain round type="text" @click="handleClose">Cancel</el-button>
        <el-button class="hiaButton" plain round :disabled="disableConfirmButton" @click="runMassUpdate(massUpdate)">Confirm</el-button>
      </el-button-group>
      <br />
    </el-dialog>
  </div>
</template>

<script>
  import projectSelector from '@/components/controls/projectSelector'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import coderSelector from '@/components/controls/coderSelector'
  import providerSelector from '@/components/controls/providerSelector'

  export default {
    name: 'ProReviewsMassUpdate',
    props: ['selectedRecords', 'selectedClients', 'showMassUpdateDialog', 'clients', 'proCodeRecord'],
    data() {
      return {
        loading: true,
        runningMassUpdate: false,
        localDropdowns: [],
        selectedProject: {},
        clientCoder: {},
        clientProvider: {},
        massUpdate: {
          updateClientNumber: false,
          updateReviewDate: false,
          updateConsultantNumber: false,
          updateProCodeRecord: false,
          updateProviderType: false,
          updateProvider: false,
          updateCoder: false,
          updatePracticeNumber: false,
          updateComments: false,
          updatePlaceOfService: false,
          updateSpecialty: false,
          overwriteComments: false,
          overwriteTags: false,
          updateTags: false,
          updateProject: false,
          updatePayor: false,
          updateStatus: false,
          proRecordIds: [],
          proRecordMassUpdateFields: {
            clientId: '',
            reviewDate: '',
            consultantNumber: '',
            providerType: '',
            providerId: null,
            coder: '',
            practiceNumber: '',
            proCodeRecord: false,
            placeOfService: '',
            specialty: '',
            comments: '',
            projectID: 0,
            payorNumber: '',
            hiaCoderId: null,
            coderId: null,
            reviewStatusId: null,
            tagsSelections: []
          }
        }
      }
    },
    methods: {
      ...mapActions('proReviews/', ['RUN_PRO_MASSUPDATE']),
      ...mapActions('clients/coders/', ['POST_CODER']),
      ...mapActions('dropdowns', ['GET_DROPDOWNS']),
      ...mapMutations('dropdowns/', ['PUSH_NEWCODER']),
      ...mapMutations('dropdowns/', ['SET_DROPDOWNS']),
      initialize() {
        this.loading = true
        this.fetchDropdowns()
      },
      async fetchDropdowns(clientNumber) {
        this.loading = true
        await this.GET_DROPDOWNS(['consultants', 'payors', 'reviewStatus', 'recordTags'])
        this.loading = false
      },
      handleClose() {
        this.$emit('close')
      },
      runMassUpdate(massUpdate) {
        if (this.validate(massUpdate)) {
          this.confirm(massUpdate)
        }
      },
      validate(massUpdate) {
        let valid = true
        if (massUpdate.updateClientNumber && !massUpdate.proRecordMassUpdateFields.clientId) {
          this.$refs.clientNumberFormItem.$el.className += ' is-error'
          valid = false
        }
        if (massUpdate.updateReviewDate) {
          if (!massUpdate.proRecordMassUpdateFields.reviewDate) {
            this.$refs.dateFormItem.$el.className += ' is-error'
            valid = false
          }
        }
        if (massUpdate.updateConsultantNumber && !massUpdate.proRecordMassUpdateFields.consultantNumber) {
          this.$refs.consultantNumberFormItem.$el.className += ' is-error'
          valid = false
        }
        if (massUpdate.updatePayor && !massUpdate.proRecordMassUpdateFields.payorNumber) {
          this.$refs.payorNumberFormItem.$el.className += ' is-error'
          valid = false
        }

        if (massUpdate.updateStatus && !massUpdate.proRecordMassUpdateFields.reviewStatusId) {
          this.$refs.statusFormItem.$el.className += ' is-error'
          valid = false
        }

        return valid
      },
      async confirm(massUpdate) {
        const updatedFields = []
        if (this.massUpdate.updateClientNumber) {
          updatedFields.push('Facility')
        }
        if (this.massUpdate.updateReviewDate) {
          updatedFields.push('Review Date')
        }
        if (this.massUpdate.updatePracticeNumber) {
          updatedFields.push('Practice Number')
        }
        if (this.massUpdate.updatePlaceOfService) {
          updatedFields.push('Place of Service')
        }
        if (this.massUpdate.updateSpecialty) {
          updatedFields.push('Specialty')
        }
        if (this.massUpdate.updateConsultantNumber) {
          updatedFields.push('Auditor')
        }
        if (this.massUpdate.updateCoder) {
          updatedFields.push('Coder')
        }
        if (this.massUpdate.updateProviderType) {
          updatedFields.push('Provider Type')
        }
        if (this.massUpdate.updateProCodeRecord) {
          updatedFields.push('Pro Code Record')
        }
        if (this.massUpdate.updateComments) {
          updatedFields.push('Comments')
        }
        if (this.massUpdate.updateProject) {
          updatedFields.push('Project')
        }
        if (this.massUpdate.updatePayor) {
          updatedFields.push('Payor')
        }
        if (this.massUpdate.updateStatus) {
          updatedFields.push('Review Status')
        }
        if (this.massUpdate.updateProvider) {
          updatedFields.push('Provider')
        }
        if (this.massUpdate.updateRecordTags) {
          updatedFields.push('Record Tags')
        }

        const fields = updatedFields.join(', ').replace(/,(?!.*,)/gmi, ' and')

        await this.$confirm('This will update ' + fields + ' for ' + this.selectedRecords.length + ' records. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })

        try {
          massUpdate.proRecordIds = this.selectedRecords
          this.runningMassUpdate = true
          await this.RUN_PRO_MASSUPDATE(massUpdate)
          this.runningMassUpdate = false
          this.$emit('massupdate')
          this.$message({
            message: 'Mass Update Successful',
            type: 'success'
          })
        } catch (err) {
          this.runningMassUpdate = false
          this.$message({
            message: 'Mass Update Error',
            type: 'error'
          })
        }
      }
    },
    mounted: function () {
      this.SET_DROPDOWNS({ CoderDropDown: [] })
      this.initialize()
    },
    computed: {
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('managedLists/projects/', ['project']),
      coderComputed: {
        get: function () {
          if (this.massUpdate.proRecordMassUpdateFields.hiaCoderId) {
            return this.massUpdate.proRecordMassUpdateFields.hiaCoderId
          }
          return this.massUpdate.proRecordMassUpdateFields.coderId
        },
        set: function (val) {
          if (!val) {
            this.massUpdate.proRecordMassUpdateFields.hiaCoderId = null
            this.massUpdate.proRecordMassUpdateFields.coderId = null
          }

          if (typeof val === 'string') {
            this.massUpdate.proRecordMassUpdateFields.hiaCoderId = val
            this.massUpdate.proRecordMassUpdateFields.coderId = null
          } else {
            this.massUpdate.proRecordMassUpdateFields.hiaCoderId = null
            this.massUpdate.proRecordMassUpdateFields.coderId = val
          }
        }
      },
      disableConfirmButton: function () {
        if (!this.massUpdate.updateReviewDate && !this.massUpdate.updateConsultantNumber && !this.massUpdate.updateCoder && !this.massUpdate.updateProCodeRecord && !this.massUpdate.updateClientNumber && !this.massUpdate.updateProviderType && !this.massUpdate.updatePracticeNumber && !this.massUpdate.updateComments && !this.massUpdate.updatePlaceOfService && !this.massUpdate.updateSpecialty && !this.massUpdate.updateProject && !this.massUpdate.updatePayor && !this.massUpdate.updateStatus && !this.massUpdate.updateProvider && !this.massUpdate.updateRecordTags) {
          return true
        }
        return false
      }
    },
    components: {
      coderSelector,
      projectSelector,
      providerSelector
    }
  }

</script>