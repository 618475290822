<template>
  <div id="massDeleteDialog">
    <el-dialog :visible="showMassDeleteDialog" width="50%" :before-close="handleClose">
      <span v-if="showButtons()" slot="title" class="el-dialog__title">{{selectedRecords.length > 0 ? 'Mass Delete ' +
        selectedRecords.length + ' Records?' : 'No Records Selected'}}</span>
      <el-row>
        <el-col class="paddingBottom" :span="24" v-loading="massDeleteLoading" element-loading-spinner="particle-loader" element-loading-text="Deleting Records...">
          <div v-if="massDeleteData.deleted.length > 0 ||  massDeleteData.notDeleted.length">
            <el-alert v-if="!massDeleteData.notDeleted.length && massDeleteData.deleted.length" :title="'All Records have been Deleted'" type="success" :closable="false" center show-icon></el-alert>
            <el-alert v-if="massDeleteData.notDeleted.length" :title="'Not All Records were Deleted'" type="warning" :closable="false" center show-icon></el-alert>
            <div v-if="massDeleteData.notDeleted.length">
              <span class="gridTitle">Not Deleted Records</span>
              <v-client-table class="hiaTable" id="notDeletedGrid" ref="notDeletedGrid" :data="massDeleteData.notDeleted" :columns="columns" :options="options">
              </v-client-table>
            </div>
            <div v-if="massDeleteData.notDeleted.length && massDeleteData.deleted.length ">
              <span class="gridTitle">Deleted Records</span>
              <v-client-table class="hiaTable" id="deletedGrid" ref="deletedGrid" :data="massDeleteData.deleted" :columns="columns" :options="options">
              </v-client-table>
            </div>
          </div>
          <v-client-table v-if="showButtons()" class="hiaTable" id="patientNumbersGrid" ref="patientNumbersGrid" :data="selectedRecords" :columns="patientNumbersGrid" :options="options">
          </v-client-table>
          <!-- <el-button v-if="showButtons()" @click="handleClose">Cancel</el-button>
          <el-button v-if="showButtons()" :disabled="!disableConfirmButton()" type="primary" @click="runMassDelete()">
            Confirm</el-button> -->
          <el-button-group style="float: right;">
            <el-button v-if="showButtons()" class="hiaButton" plain round type="text" @click="handleClose">Cancel</el-button>
            <el-button v-if="showButtons()" class="hiaButton" plain round :disabled="!disableConfirmButton()" @click="runMassDelete()">Confirm</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import hiaTable from '@/mixins/hiaTable'

  export default {
    name: 'ReviewsMassDelete',
    props: ['selectedRecords', 'showMassDeleteDialog', 'massDeleteData', 'massDeleteLoading', 'idType'],
    mixins: [hiaTable],
    data() {
      return {
        columns: ['patientNumber', 'message', 'id'],
        patientNumbersGrid: ['patientNumber'],
        options: {
          // see the options API
          filterByColumn: true,
          orderBy: {
            column: 'patientNumber',
            ascending: true
          },
          columnsClasses: {
          },
          headings: {
            patientNumber: function (h) {
              return 'Patient Number'
            },
            message: function (h) {
              return 'Message'
            },
            id: function (h) {
              return 'Record ID'
            }
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit('close')
      },
      runMassDelete() {
        this.$confirm('This will delete ' + this.selectedRecords.length + ' records. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.$emit('massDelete', null)
        })
      },
      disableConfirmButton() {
        return this.selectedRecords.length > 0
      },
      showButtons() {
        if (this.massDeleteData.deleted.length > 0 || this.massDeleteData.notDeleted.length > 0) {
          return false
        }
        return true
      }
    },
    watch: {
    },
    components: {
    }
  }

</script>
<style scoped>
.paddingBottom {
  padding-bottom: 40px;
}

.gridTitle {
  font-size: 16px;
}
</style>
