<template>
  <div id="recordValidation">
    <el-row>
      <el-col style="margin: 10px 0px 10px 10px;" :span="23">
        <el-table :data="validationMessages" size="mini" style="word-break: break-word;" :height="(validationMessages.length * 55) + 40">
          <el-table-column prop="severity" label="Severity" width="80"></el-table-column>
          <el-table-column prop="message" label="Validation Message" width="365"></el-table-column>
          <el-table-column label="Details">
            <template slot-scope="scope">
              <span v-html="scope.row.details"></span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'RecordValidation',
  props: ['validationMessages'],
  data() {
    return {
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}

</script>

<style scoped>
>>> .cell {
  word-break: break-word;
}

>>> .el-table:before {
  height: 0px;
}
</style>
