<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div style="display: grid; grid-template-columns: 275px 33px;">
          <hia-el-select :disabled="disabled" style="grid-column: 1/2; width: 275px;" @input="emitValue" :value="value" itemkey="id" label="mappingName"
            :dropdowns="calcPullListBuilds" filterable>
          </hia-el-select>
          <el-button style="grid-column: 2/2;" class="search" icon="el-icon-search" size="mini" @click="showMapping">
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog :id="getUniqueDialogId()" title="Mapping Details" :visible.sync="mappingVisible" width="90%">
      <PullListBuild :id="value" :isModal="true"></PullListBuild>
    </el-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import PullListBuild from '@/views/reviews/pulllists/PullListBuild.vue'
  export default {
    name: 'mappingSelector',
    props: ['value', 'disabled', 'patientType', 'projectTypeId'],
    data() {
      return {
        mappingVisible: false
      }
    },
    methods: {
      getUniqueDialogId() {
        return `coderSelectorDialog${this._uid}`
      },
      showMapping() {
        this.mappingVisible = true
      },
      emitValue(val) {
        this.$emit('input', val)
      },
      selectMapping(row) {
        this.$emit('input', row.pullListBuildId)
        this.$emit('selected', row)
        this.mappingVisible = false
      },
      initialize() {
      }
    },
    components: {
      PullListBuild
    },
    computed: {
      ...mapState('pulllists/', ['pullListBuilds']),
      calcPullListBuilds() {
        if (this.projectTypeId) {
          return this.pullListBuilds.filter(x => x.projectTypeId === this.projectTypeId)
        }
        return this.pullListBuilds
      }
    },
    created: function () {
      this.initialize()
    }
  }
</script>

<style scoped>
  >>>.el-input__inner {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  >>>.el-button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    height: 22px;
    padding: 5px 10px;
  }

  >>>.el-dialog__body {
    padding-top: 0px;
    word-break: unset;
  }

  >>>.select-button {
    border: 2px solid rgba(255, 255, 255, 0);
    padding: 7px 5px 7px 5px;
    width: 100%;
    text-align: left;
  }

  >>>.select-button:focus {
    border: 2px solid #409eff;
    border-radius: 0px;
  }
</style>
