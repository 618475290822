
const filterList = (array, criteria) => {
  // set array keys
  // console.log(array)
  if ((array)) {
    const objKeys = Object.keys(array[0])
    const objKeysLength = objKeys.length

    // filter on every key for the given criteria
    return array.filter(function (obj) {
      // delete falsy attributes in this obj
      for (let j = 0; j < objKeysLength; j++) {
        if (Object.prototype.hasOwnProperty.call(obj, objKeys[j]) && obj[objKeys[j]] !== 0 && !obj[objKeys[j]]) {
          delete obj[objKeys[j]]
        }
      }
      // return match
      for (const i in obj) {
        if (obj[i].toString().toLowerCase().indexOf(criteria.toLowerCase()) > -1) {
          return true
        }
      }
      return false
    })
  }
}

const filterColumn = (array, columnKey, criteria) => {
  if ((array)) {
    return array.filter((x) => {
      if (isNaN(x[columnKey])) {
        return x[columnKey].toUpperCase().indexOf(criteria.toString().toUpperCase()) > -1
      }
      return x[columnKey].toString().indexOf(criteria.toString()) > -1
    })
  }
}

const getFilteredRows = (reviews, filterCriteria) => {
  for (const filter in filterCriteria) {
    if (Object.prototype.hasOwnProperty.call(filterCriteria, filter)) {
      if (filterCriteria[filter] !== '') {
        reviews = reviews.filter((review) => {
          const fieldCriteria = filterCriteria[filter].toLowerCase()
          return review[filter] ? review[filter].toString().toLowerCase().includes(fieldCriteria) : false
        })
      }
    }
  }
  // }
  return reviews
}

const selectAll = (listToSelect, checked) => {
  const length = listToSelect.length
  for (let i = 0; i < length; i++) {
    if (checked) {
      listToSelect[i].selected = true
    } else {
      listToSelect[i].selected = false
    }
  }
}

const getSelectedRecords = (listToSearch, keyField) => {
  const selectedRecords = []
  if (listToSearch) {
    const length = listToSearch.length
    for (let i = 0; i < length; i++) {
      if (listToSearch[i].selected) {
        selectedRecords.push(listToSearch[i][keyField])
      }
    }
  }

  return selectedRecords
}

const selectRecord = (listToSelect, keyFieldName, keyFieldValue, checked) => {
  const length = listToSelect.length
  for (let i = 0; i < length; i++) {
    if (listToSelect[i][keyFieldName] === keyFieldValue) {
      if (checked) {
        listToSelect[i].selected = true
      } else {
        listToSelect[i].selected = false
      }
      break
    }
  }
}

export default {
  filterList,
  filterColumn,
  getFilteredRows,
  selectAll,
  getSelectedRecords,
  selectRecord
}
