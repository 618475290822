<template>
  <div>
    <div v-if="loading" v-loading="loading" style="height: 450px" element-loading-spinner="atom-audit-loader"></div>
    <div v-if="!loading">
      <el-form size="mini" label-position="left" label-width="150px">
        <el-row :gutter="20">
          <el-col :xl="12" :lg="16" :sm="20">
            <el-form-item class="is-required" label="Mapping">
              <div style="display: grid; grid-template-columns: 350px">
                <mappingSelector v-model="calcMapping" :disabled="readOnly() && !projectManagement()" :projectTypeId="project.projectTypeId"
                  style="grid-column: 1/2; width: 275px; margin-right: 15px;"></mappingSelector>
                <div style="grid-column: 2/2;" v-if="isDefaultMapping">
                  <el-popover placement="right" ref="templatePopover" title="Import Templates" width="300" trigger="click">
                    <div style="margin-top: 20px;">
                      <a :href="setTemplatePath()">Empty {{ getRecordType() }} Template</a>
                    </div>
                    <div style="margin-top: 15px;">
                      <a :href="setSampleTemplatePath()">Sample {{ getRecordType() }} Template</a>
                    </div>
                    <el-button type="text" size="med" slot="reference" style="padding: 0px;">Download Template</el-button>
                  </el-popover>
                </div>
              </div>
            </el-form-item>

            <el-form-item class="is-required" prop="codeType" label="Code Type" v-if="compProjectType === 'op' || compProjectType === 'pro' || compProjectType === 'asc'">
              <hia-el-select style="width: 275px" :disabled="readOnly() && !projectManagement()" @input="handleDefaultCodeTypeSelected" v-model="compDefaultCodeType"
                :filterable="true" :itemkey="'key'" :label="'value'" :dropdowns="dropdowns.codeTypes.list"> </hia-el-select>
            </el-form-item>

            <input id="importFileNew" data="test" style="display: none;" name="txttoImport" type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" @change="onFileChange" />

            <el-form-item style="margin: 15px 0px 20px 0px;" label="Choose Import File">
              <div style="display: grid; grid-template-columns: 120px">
                <el-button style="grid-column: 1/2; width: 100px" :disabled="readOnly() && !projectManagement()" class="hiaButton" icon="mdi mdi-file-upload-outline" round
                  plain size="mini" @click="handleSelect"> Select</el-button>
                <div slot="reference" style="grid-column: 2/2; font-size: 14px; font-style: italic; margin-top: 8px;">
                  <el-popover popper-class="popover" v-model="showImportPopover" ref="importPopover" placement="right" width="250" :content="importPopoverContent"
                    trigger="manual" @after-enter="handleHidePopOver('importPopover')">
                    <span slot="reference">{{ importFileNewName }}</span>
                  </el-popover>
                </div>
              </div>
            </el-form-item>

            <el-button class="hiaButton" round :disabled="isProcessDisabled" :type="!isProcessDisabled ? 'primary' : ''" @click="runWorker">Process Mapping </el-button>
          </el-col>
        </el-row>
        <h4>Import Preview</h4>
        <v-client-table v-if="toggleTable" v-loading="tableLoading" element-loading-spinner="atom-audit-loader" class="hiaTable" id="importGrid" ref="importGrid"
          :data="importList" :columns="getColumns()" :options="getOptions()">
          <template slot="Errors" slot-scope="props">
            <div style="display: flex">
              <i v-if="props.row.hasErrors" class="mdi mdi-alert-circle" style="flex: 10%; color: red; font-weight: bold; margin-left: 3px; font-size: 14px;"></i>
              <ul style="flex: 90%; margin: 0px 5px 0px 0px; padding: 0; list-style-type: none;">
                <li class="li-margin-bottom" v-for="row in props.row.Errors" :key="row.message">{{ row.message }}</li>
              </ul>
            </div>
          </template>
          <template slot="PatientNumber" slot-scope="props">
            <span :title="JSON.stringify(props.row)">{{ props.row.PatientNumber }}</span>
          </template>
          <template slot="DxCodes" slot-scope="props">
            {{ props.row.dxs.length }}
          </template>
          <template slot="PxCodes" slot-scope="props">
            {{ props.row.pxs.length }}
          </template>
          <template slot="CptCodes" slot-scope="props">
            {{ props.row.cpts.length }}
          </template>
        </v-client-table>
        <el-row style="margin-top: 20px;">
          <el-col>
            <el-button-group style="float: right;">
              <el-button class="hiaButton" plain round size="mini" type="text" @click="clearPreview()" v-if="importList.length > 0">Clear</el-button>
              <el-button style="float: right; border-top-left-radius: 0; border-bottom-left-radius: 0;" class="hiaButton" round :type="importList.length > 0 ? 'primary' : ''"
                :disabled="importList.length === 0 || hasDataErrors" @click="runImport">Import Records </el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </el-form>
      <el-alert v-if="errorMessage" class="topMargin" :closable="false" type="warning" title="" show-icon center>
        {{ errorMessage }}
      </el-alert>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { format, parseISO } from 'date-fns'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import mappingSelector from '@/components/controls/mappingSelector'

  export default {
    name: 'RecordImport',
    mixins: [HiaAuthorization],
    props: ['projectDropdownsNeeded'],
    data() {
      return {
        hasDataErrors: false,
        showGrid: false,
        toggleTable: true,
        templatePath: null,
        inputFiles: [],
        recordType: 'ip',
        importList: [],
        importFileNewName: '',
        importPopoverContent: null,
        showImportPopover: false,
        selectedLocalMapping: null,
        loading: true,
        tableLoading: false,
        errorMessage: '',
        dropdownsNeeded: ['clients', 'opPatientTypes'],
        defaultCodeType: null,
        clientCoder: {},
        clientProvider: {},
        clientServiceLine: {},
        ipPreviewTableColumns: ['ReviewDate', 'PatientNumber', 'MrNumber', 'AdmitDate', 'DischargeDate', 'OriginalDrg', 'Coder', 'Provider', 'ServiceLine', 'DxCodes', 'PxCodes'],
        proPreviewTableColumns: ['ReviewDate', 'PatientNumber', 'MrNumber', 'DateOfService', 'Coder', 'Provider', 'ServiceLine', 'DxCodes', 'CptCodes'],
        opPreviewTableColumns: ['ReviewDate', 'PatientNumber', 'MrNumber', 'DateOfService', 'PatientType', 'Coder', 'Provider', 'ServiceLine', 'Grouper', 'DxCodes', 'CptCodes'],
        options: {
          filterByColumn: true,
          orderBy: {
            column: 'hasErrors',
            ascending: false
          },
          headings: {
            PatientNumber: 'Patient Number',
            MrNumber: 'MR Number',
            AdmitDate: 'Admit Date',
            DischargeDate: 'Discharge Date',
            ReviewDate: 'Review Date',
            OriginalDrg: 'Original DRG',
            ServiceLine: 'Service Line',
            DxCodes: '# Dxs',
            PxCodes: '# Pxs',
            CptCodes: '# CPTs'
          },
          perPage: 20,
          highlightMatches: true,
          sortIcon: { is: 'none' },
          filterAlgorithm: {
            DxCodes(row, query) {
              if (!isNaN(query)) {
                return row.dxs.length.toString() === query
              }
            },
            PxCodes(row, query) {
              if (!isNaN(query)) {
                return row.pxs.length.toString() === query
              }
            }
          },
          customSorting: {
            DxCodes: function (ascending) {
              return function (a, b) {
                const lastA = a.dxs.length
                const lastB = b.dxs.length

                if (ascending) { return lastA >= lastB ? 1 : -1 }

                return lastA <= lastB ? 1 : -1
              }
            },
            PxCodes: function (ascending) {
              return function (a, b) {
                const lastA = a.pxs.length
                const lastB = b.pxs.length

                if (ascending) { return lastA >= lastB ? 1 : -1 }

                return lastA <= lastB ? 1 : -1
              }
            }
          }
        },
        opOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'hasErrors',
            ascending: false
          },
          headings: {
            PatientNumber: 'Patient Number',
            MrNumber: 'MR Number',
            DateOfService: 'Date of Service',
            ReviewDate: 'Review Date',
            ServiceLine: 'Service Line',
            PatientType: 'Patient Type',
            DxCodes: '# Dxs',
            PxCodes: '# Pxs',
            CptCodes: '# CPTs'
          },
          perPage: 20,
          highlightMatches: true,
          sortIcon: { is: 'none' },
          filterAlgorithm: {
            DxCodes(row, query) {
              if (!isNaN(query)) {
                return row.dxs.length.toString() === query
              }
            },
            CptCodes(row, query) {
              if (!isNaN(query)) {
                return row.cpts.length.toString() === query
              }
            }
          },
          customSorting: {
            DxCodes: function (ascending) {
              return function (a, b) {
                const lastA = a.dxs.length
                const lastB = b.dxs.length

                if (ascending) { return lastA >= lastB ? 1 : -1 }

                return lastA <= lastB ? 1 : -1
              }
            },
            CptCodes: function (ascending) {
              return function (a, b) {
                const lastA = a.cpts.length
                const lastB = b.cpts.length

                if (ascending) { return lastA >= lastB ? 1 : -1 }

                return lastA <= lastB ? 1 : -1
              }
            }
          }
        },
        proOptions: {
          filterByColumn: true,
          orderBy: {
            column: 'hasErrors',
            ascending: false
          },
          headings: {
            PatientNumber: 'Patient Number',
            MrNumber: 'MR Number',
            DateOfService: 'Date of Service',
            ReviewDate: 'Review Date',
            ServiceLine: 'Service Line',
            DxCodes: '# Dxs',
            PxCodes: '# Pxs',
            CptCodes: '# CPTs'
          },
          perPage: 20,
          highlightMatches: true,
          sortIcon: { is: 'none' },
          filterAlgorithm: {
            DxCodes(row, query) {
              if (!isNaN(query)) {
                return row.dxs.length.toString() === query
              }
            },
            CptCodes(row, query) {
              if (!isNaN(query)) {
                return row.cpts.length.toString() === query
              }
            }
          },
          customSorting: {
            DxCodes: function (ascending) {
              return function (a, b) {
                const lastA = a.dxs.length
                const lastB = b.dxs.length

                if (ascending) { return lastA >= lastB ? 1 : -1 }

                return lastA <= lastB ? 1 : -1
              }
            },
            CptCodes: function (ascending) {
              return function (a, b) {
                const lastA = a.cpts.length
                const lastB = b.cpts.length

                if (ascending) { return lastA >= lastB ? 1 : -1 }

                return lastA <= lastB ? 1 : -1
              }
            }
          }
        }
      }
    },
    methods: {
      ...mapActions('inpatientReviews/', ['POST_DRG_TEMPLATE_MULTIPLE']),
      ...mapActions('outpatientReviews/', ['POST_OP_TEMPLATE_MULTIPLE']),
      ...mapActions('proReviews/', ['POST_PRO_TEMPLATE_MULTIPLE']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS', 'GET_DROPDOWNS_FORCE']),
      ...mapActions('pulllists/', ['GET_PULLLIST_BUILDS', 'GET_TARGET_TEMPLATES']),
      ...mapActions('managedLists/tenantSetup/', ['GET_TENANT_SETUP']),
      async runWorker() {
        this.hasDataErrors = false

        if (!this.inputFiles) {
          return
        }

        const row = this.pullListBuilds.find(x => x.id === this.calcMapping)
        const pullListBuild = JSON.parse(row.pullListBuild)

        this.tableLoading = true

        const callback = data => {
          this.importList = data.map(x => {
            x.projectId = this.project.id
            x.defaultCodeType = this.defaultCodeType
            x.ReviewDate = x.ReviewDate || format(parseISO(this.project.startDate), 'M/d/yyyy')
            x.Grouper = this.compProjectType === 'asc' ? 'ASC' : this.compProjectType === 'op' ? 'APC' : null
            x.dxs = this.getDxCodes(x)
            x.pxs = this.getPxCodes(x)
            x.cpts = this.getCptCodes(x)
            x.recordTags = this.getRecordTags(x)
            if (x.hasErrors) {
              this.addErrorColumn()
              this.hasDataErrors = true
              this.showImportPopover = true
              this.importPopoverContent = 'Source data has errors, please correct data in source file, re-choose file and click Process Mapping to try again'
            }
            return x
          })

          if (!this.hasDataErrors) {
            this.removeErrorColumn()
          }
          this.tableLoading = false
        }

        const worker = new Worker('@hiacode/pulllist-worker/index.js', { type: 'module' })

        const message = {
          importFile: this.inputFiles[0],
          readAllSheets: pullListBuild.readAllSheets,
          opts: { dense: true },
          pullListBuild: pullListBuild,
          validations: this.compTargetTemplate.validations,
          targetTemplate: this.compTargetTemplate,
          operation: 'readFileAndMapList',
          defaults: { Facility: this.project.client?.clientName }
        }
        worker.postMessage(message)
        worker.onmessage = response => {
          if (response.data.type === 'status') {
            console.log(response.data.statusMessage)
          } else {
            callback(response.data)
            worker.terminate()
          }
        }
      },
      addErrorColumn() {
        let columns = this.getColumns()
        if (!columns.some(x => x === 'Errors')) {
          columns = columns.unshift('Errors')
          this.toggleTable = false
          this.$nextTick(() => {
            this.toggleTable = true
          })
        }
      },
      removeErrorColumn() {
        let columns = this.getColumns()
        if (columns.some(x => x === 'Errors')) {
          columns = columns.shift()
        }
      },
      getColumns() {
        switch (this.compProjectType) {
          case 'ip':
            return this.ipPreviewTableColumns
          case 'op':
            return this.opPreviewTableColumns
          case 'asc':
            return this.opPreviewTableColumns
          case 'pro':
            return this.proPreviewTableColumns
        }
        return []
      },
      getOptions() {
        switch (this.compProjectType) {
          case 'ip':
            return this.options
          case 'op':
            return this.opOptions
          case 'asc':
            return this.opOptions
          case 'pro':
            return this.proOptions
        }
        return {}
      },
      clearPreview() {
        this.importFileNewName = null
        this.importList = []
        this.inputFiles = []
      },
      getDxCodes(row) {
        const dxCodes = []
        for (const [key, value] of Object.entries(row)) {
          if (key.toUpperCase().startsWith('DX') && !isNaN(key.toUpperCase().replace('DX', ''))) {
            if (value && value.trim().length > 0) {
              dxCodes.push({
                originalDx: value,
                originalPoa: this.getPoa(row, key),
                sequence: parseInt(key.toUpperCase().replace('DX', ''))
              })
            }
            delete row[key]
          }
        }
        return dxCodes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      },
      getPoa(row, dxKey) {
        const dxNumber = dxKey.toUpperCase().replace('DX', '')
        for (const [key, value] of Object.entries(row)) {
          if (key.toUpperCase().startsWith('POA') && key.toUpperCase().replace('POA', '') === dxNumber) {
            delete row[key]
            if (value && value.trim().length > 0) {
              return value
            }
            return null
          }
        }
        return null
      },
      getPxCodes(row) {
        const pxCodes = []
        for (const [key, value] of Object.entries(row)) {
          if (key.toUpperCase().startsWith('PX') && !isNaN(key.toUpperCase().replace('PX', ''))) {
            if (value && value.trim().length > 0) {
              pxCodes.push({
                originalPx: value,
                sequence: parseInt(key.toUpperCase().replace('PX', ''))
              })
            }
            delete row[key]
          }
        }
        return pxCodes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      },
      getRecordTags(row) {
        if (row.Tags) {
          const arr = row.Tags.split(',')
          if (arr) {
            return arr.map(x => x.trim())
          }
        }
        return []
      },
      getCptCodes(row) {
        const cptCodes = []
        for (const [key, value] of Object.entries(row)) {
          if (key.toUpperCase().startsWith('CPT') && !isNaN(key.toUpperCase().replace('CPT', ''))) {
            if (value && value.trim().length > 0) {
              cptCodes.push({
                originalCpt: value,
                modifiers: this.getCptModifiers(row, key),
                sequence: parseInt(key.toUpperCase().replace('CPT', ''))
              })
            }
            delete row[key]
          }
        }

        // remove any remaining modifier keys
        for (const [key] of Object.entries(row)) {
          if (key.toUpperCase().startsWith('MODIFIERS')) {
            delete row[key]
          }
        }

        return cptCodes.sort((a, b) => (a.sequence > b.sequence ? 1 : -1))
      },
      getCptModifiers(row, cptKey) {
        const cptNumber = cptKey.toUpperCase().replace('CPT', '')
        for (const [key, value] of Object.entries(row)) {
          if (key.toUpperCase().startsWith('MODIFIERS') && key.toUpperCase().replace('MODIFIERS', '') === cptNumber && value) {
            delete row[key]
            const mods = value.split(',')
            return mods.map((x, i) => {
              return {
                OriginalModifier: x.trim(),
                sequence: i + 1
              }
            })
          }
        }
        return []
      },
      handleDefaultCodeTypeSelected(e) {
        this.importList = this.importList.map(x => {
          x.defaultCodeType = this.defaultCodeType
          return x
        })
      },
      getPatientType(patientTypeId) {
        if (patientTypeId) {
          const pt = this.dropdowns.opPatientTypes.list.find(x => x.key === patientTypeId)
          if (pt) {
            return pt.value
          }
        }
        return null
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      handleSelect() {
        this.resetFileInput()
        const input = document.getElementById('importFileNew')
        input.click()
      },
      resetFileInput() {
        const input = document.getElementById('importFileNew')
        input.value = null
      },
      onFileChange(e) {
        const input = document.getElementById('importFileNew')
        this.inputFiles = input.files
        if (!this.inputFiles) {
          return
        }
        this.importFileNewName = this.inputFiles[0].name
      },
      async runImport() {
        if (this.importList.length > 0) {
          await this.$confirm(`Import ${this.importList.length} records. Continue?`, 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })

          this.tableLoading = true

          try {
            if (this.compProjectType === 'ip') {
              await this.POST_DRG_TEMPLATE_MULTIPLE(this.importList)
              this.$message.info('Records Imported Successfully')
              this.refreshIpList()
            }
            if (this.compProjectType === 'op' || this.compProjectType === 'asc') {
              await this.POST_OP_TEMPLATE_MULTIPLE(this.importList)
              this.$message.info('Records Imported Successfully')
              this.refreshOpList()
            }
            if (this.compProjectType === 'pro') {
              await this.POST_PRO_TEMPLATE_MULTIPLE(this.importList)
              this.$message.info('Records Imported Successfully')
              this.refreshProList()
            }
          } catch (e) {
            this.tableLoading = false
            this.$message({
              dangerouslyUseHTMLString: true,
              duration: 5000,
              type: 'error',
              message: e.message
            })
            return
          }

          await this.GET_DROPDOWNS_FORCE(this.projectDropdownsNeeded)

          this.clearPreview()

          this.tableLoading = false
        } else {
          console.log('handle error')
        }
      },
      getRecordType() {
        switch (this.compProjectType) {
          case 'ip':
            return 'Inpatient'
          case 'op':
            return 'Outpatient'
          case 'pro':
            return 'Pro'
          case 'asc':
            return 'Ambulatory'
        }
        return null
      },
      setTemplatePath() {
        switch (this.compProjectType) {
          case 'ip':
            return '/static/InpatientImportTemplate.xlsx'
          case 'op':
            return '/static/OutpatientImportTemplate.xlsx'
          case 'pro':
            return '/static/ProviderImportTemplate.xlsx'
          case 'asc':
            return '/static/AmbulatoryImportTemplate.xlsx'
        }
        return null
      },
      setSampleTemplatePath() {
        switch (this.compProjectType) {
          case 'ip':
            return '/static/SampleInpatientImportTemplate.xlsx'
          case 'op':
            return '/static/SampleOutpatientImportTemplate.xlsx'
          case 'pro':
            return '/static/SampleProviderImportTemplate.xlsx'
          case 'asc':
            return '/static/SampleAmbulatoryImportTemplate.xlsx'
        }
        return null
      }
    },
    mounted: async function () {
      this.loading = true
      await Promise.all([this.GET_DROPDOWNS(this.dropdownsNeeded), this.GET_PULLLIST_BUILDS(), this.GET_TARGET_TEMPLATES(), this.GET_TENANT_SETUP()])
      this.loading = false
    },
    deactivated: function () {
      this.clearPreview()
    },
    watch: {
      compProjectType() {
        // this fixes a keep alive issue with the vue-table 2 columns being different
        // across different project types
        this.toggleTable = false
        this.$nextTick(() => {
          this.toggleTable = true
        })
      }
    },
    computed: {
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapState('inpatientReviews/', ['refreshIpList']),
      ...mapState('outpatientReviews/', ['refreshOpList']),
      ...mapState('proReviews/', ['refreshProList']),
      ...mapState('managedLists/projects/', ['project']),
      ...mapState('pulllists/', ['pullListBuilds', 'targetTemplates']),
      ...mapState('managedLists/tenantSetup/', ['tenantSetup']),
      compTargetTemplate() {
        if (this.targetTemplates) {
          if (this.project.projectTypeName === 'Inpatient') {
            return this.targetTemplates.inpatient
          }
          if (this.project.projectTypeName === 'Outpatient') {
            return this.targetTemplates.outpatient
          }
          if (this.project.projectTypeName === 'Professional Fee') {
            return this.targetTemplates.pro
          }
          if (this.project.projectTypeName === 'Ambulatory') {
            return this.targetTemplates.outpatient
          }
        }
        return {}
      },
      compProjectType() {
        if (this.project.projectTypeName === 'Inpatient') {
          return 'ip'
        }
        if (this.project.projectTypeName === 'Outpatient') {
          return 'op'
        }
        if (this.project.projectTypeName === 'Professional Fee') {
          return 'pro'
        }
        if (this.project.projectTypeName === 'Ambulatory') {
          return 'asc'
        }
        return ''
      },
      isDefaultMapping() {
        const mapping = this.pullListBuilds.find(x => x.id === this.calcMapping)
        if (!mapping) {
          return false
        }

        if (this.project.projectTypeName === 'Inpatient') {
          return mapping.mappingName === 'Default Inpatient Mapping'
        }
        if (this.project.projectTypeName === 'Outpatient') {
          return mapping.mappingName === 'Default Outpatient Mapping'
        }
        if (this.project.projectTypeName === 'Professional Fee') {
          return mapping.mappingName === 'Default Provider Mapping'
        }
        if (this.project.projectTypeName === 'Ambulatory') {
          return mapping.mappingName === 'Default Ambulatory Mapping'
        }
        return false
      },
      calcMapping: {
        get: function () {
          if (this.selectedLocalMapping) {
            return this.selectedLocalMapping
          }
          if (this.project.projectTypeName === 'Inpatient') {
            return this.tenantSetup.inpatientMapping
          }
          if (this.project.projectTypeName === 'Outpatient') {
            return this.tenantSetup.outpatientMapping
          }
          if (this.project.projectTypeName === 'Professional Fee') {
            return this.tenantSetup.providerMapping
          }
          if (this.project.projectTypeName === 'Ambulatory') {
            return this.tenantSetup.ambulatoryMapping
          }
          return null
        },
        set: function (val) {
          this.selectedLocalMapping = val
        }
      },
      isProcessDisabled() {
        if (!this.calcMapping) {
          return true
        }
        if ((this.compProjectType === 'op' || this.compProjectType === 'pro' || this.compProjectType === 'asc') && !this.compDefaultCodeType) {
          return true
        }
        if (this.inputFiles.length < 1) {
          return true
        }
        return false
      },
      compDefaultCodeType: {
        get() {
          return this.defaultCodeType || this.project.defaultCodeType
        },
        set(val) {
          this.defaultCodeType = val
        }
      }
    },
    components: {
      mappingSelector
    }
  }
</script>

<style scoped>
  .el-button--primary:hover,
  .el-button--primary:focus {
    color: #fff !important;
  }

  .li-margin-bottom:first-child {
    margin-bottom: 8px;
  }
</style>