<template>
  <div>

    <portal to="ambListStatusMultiSelect">
      <el-select id="rfcFilter" v-loading="projectLoading" element-loading-spinner="none" v-model="projectStatusMultiSelect" placeholder="" :clearable="false" multiple
        :collapse-tags="true" @remove-tag="handleProjectStatusMultiSelect()" @input="handleProjectStatusMultiSelect">
        <el-option v-for="item in dropdowns.projectStatus.list" :key="item.value" :label="item.value" :value="item.value"> </el-option>
      </el-select>
    </portal>

    <v-client-table v-if="showTable" key="advanced" style="min-height: 120px;" class="hiaTable" ref="reviewListGrid" id="reviewListGrid" :data="projectListCalc"
      :columns="displayColumns" :options="options" v-loading="projectLoading" element-loading-spinner="atom-audit-loader">

      <template slot="filter__projectStatus">
        <portal-target name="ambListStatusMultiSelect"> </portal-target>
      </template>

      <template slot="projectName" slot-scope="props">
        <div class="overflow" :title="props.row.projectName">
          <router-link v-bind:to="'/projects/' + props.row.projectID">{{ props.row.projectName ? props.row.projectName : '' }}</router-link>
        </div>
      </template>

      <template slot="clientName" slot-scope="props">
        <div class="overflow" :title="props.row.clientName">
          {{ props.row.clientName }}
        </div>
      </template>

      <template slot="leadName" slot-scope="props">
        <div class="overflow" :title="props.row.leadName">
          {{ props.row.leadName }}
        </div>
      </template>

      <template slot="process" slot-scope="props">
        <div class="overflow" :title="props.row.process">
          {{ props.row.process }}
        </div>
      </template>

      <template slot="startDate" slot-scope="props">
        <div>
          {{ props.row.startDate ? formatDate(props.row.startDate) : '' }}
        </div>
      </template>
      <template slot="endDate" slot-scope="props">
        <div>
          {{ props.row.endDate ? formatDate(props.row.endDate) : '' }}
        </div>
      </template>

      <template slot="coderReviewedDate" slot-scope="props">
        <div>
          {{ props.row.coderReviewedDate ? formatDate(props.row.coderReviewedDate) : '' }}
        </div>
      </template>

      <template slot="providerReviewedDate" slot-scope="props">
        <div>
          {{ props.row.providerReviewedDate ? formatDate(props.row.providerReviewedDate) : '' }}
        </div>
      </template>

      <template slot="responseCompleteDate" slot-scope="props">
        <div>
          {{ props.row.responseCompleteDate ? formatDate(props.row.responseCompleteDate) : '' }}
        </div>
      </template>

      <template slot="identifiers" slot-scope="props">
        <template v-if="props.row.identifiers">
          <div class="el-tag el-tag--mini el-tag--light" style="max-width: 100px; " v-for="tag in props.row.identifiers.split(',')" :key="tag">
            <div :title="tag" style="overflow: hidden; text-overflow: ellipsis;">
              {{ tag }}
            </div>
          </div>
        </template>
      </template>

      <template slot="cptWithoutReimb" slot-scope="props">
        {{ formatPercentage(props.row.cptWithoutReimb) }}
      </template>

      <template slot="cptWithReimb" slot-scope="props">
        {{ formatPercentage(props.row.cptWithReimb) }}
      </template>

      <template slot="totalCodes" slot-scope="props">
        {{ formatPercentage(props.row.totalCodes) }}
      </template>

      <template slot="cmCodes" slot-scope="props">
        {{ formatPercentage(props.row.cmCodes) }}
      </template>

      <template slot="pcsCodes" slot-scope="props">
        {{ formatPercentage(props.row.pcsCodes) }}
      </template>

      <template slot="cptCodes" slot-scope="props">
        {{ formatPercentage(props.row.cptCodes) }}
      </template>

      <template slot="modCodes" slot-scope="props">
        {{ formatPercentage(props.row.modCodes) }}
      </template>

      <template slot="emCodes" slot-scope="props">
        {{ formatPercentage(props.row.emCodes) }}
      </template>

      <template slot="iiCodes" slot-scope="props">
        {{ formatPercentage(props.row.iiCodes) }}
      </template>

      <template slot="siCodes" slot-scope="props">
        {{ formatPercentage(props.row.siCodes) }}
      </template>

      <template slot="ascFinancialImpact" slot-scope="props">
        <span :class="`${props.row.ascFinancialImpact < 0 ? 'negative' : ''}`"> ${{ formatMoney(props.row.ascFinancialImpact) }}</span>
      </template>

      <template slot="description" slot-scope="props">
        <div class="overflow" :title="props.row.description">
          {{ props.row.description }}
        </div>
      </template>

      <template slot="delete" slot-scope="props">
        <el-popover v-if="projectManagement()" :ref="'delete_' + props.row.projectID" style="word-break: normal;" @show="handleDeletePopover(props.row)"
          :popper-class="'delete_' + props.row.projectID" trigger="click" placement="left" width="200">
          <center>
            <p style="word-break: normal;">{{ deletePopperContent }}</p>
            <el-button-group>
              <el-button class="hiaButton" plain round type="text" size="mini" @click="deleteProject(props.row, 'delete_' + props.row.projectID, false)">Cancel</el-button>
              <el-button :disabled="!canDeleteProject(props.row)" class="hiaButton" plain round size="mini"
                @click="deleteProject(props.row, 'delete_' + props.row.projectID, true)">Confirm</el-button>
            </el-button-group>
          </center>
          <div slot="reference">
            <div title="delete" style="text-align: center; cursor: pointer;">
              <i class="el-icon-close delete-button"></i>
            </div>
          </div>
        </el-popover>
      </template>
    </v-client-table>
    <div style="margin-top: -25px;">
      <small class="pull-right"><i>*Accuracy statistics are based on records in Ready for Coder or Completed status</i></small>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
  import dateHelper from '@/mixins/date-helper'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import { format, parseISO } from 'date-fns'
  import dashboardHelper from '@/mixins/dashboardHelper'
  import money from '@/mixins/money'

  export default {
    name: 'ambulatoryReviewList',
    mixins: [dateHelper, HiaAuthorization, dashboardHelper, money],
    props: ['selectedCodeType', 'selectedProcess'],
    data() {
      return {
        format: format,
        parseISO: parseISO,
        showTable: true,
        projectStatusMultiSelect: [],
        projectStatusMultiSelectBackup: [],
        columns: ['projectName', 'clientName', 'identifiers', 'leadName', 'startDate', 'endDate', 'allRecords', 'recordsNotStarted', 'recordsInProgress', 'recordsReadyForCoder', 'recordsCompleted', 'process', 'projectStatus', 'totalCodes', 'cmCodes', 'cptWithReimb', 'cptWithoutReimb', 'modCodes', 'ascFinancialImpact', 'description', 'delete'],
        displayColumns: [],
        options: {
          actionsPosition: 'right',
          filterByColumn: true,
          listColumns: {},
          columnsClasses: {
            projectName: 'wideColumn',
            totalCodes: 'statCol',
            cmCodes: 'statCol',
            projectStatus: 'mediumColumn',
            cptWithReimb: 'statCol',
            cptWithoutReimb: 'statCol',
            modCodes: 'statCol',
            ascFinancialImpact: 'finImpactCol',
            identifiers: 'tagsCol'
          },
          orderBy: {
            column: 'startDate',
            ascending: false
          },
          headings: {
            clientName: 'Facility',
            projectName: 'Project Name',
            name: 'Coder',
            identifier: 'Tags',
            identifiers: 'Tags',
            leadName: 'Lead',
            startDate: 'Start Date',
            endDate: 'End Date',
            projectTypeName: 'Project Type',
            projectStatus: 'Project Status',
            coderReviewedDate: 'Coder Review Complete',
            providerReviewedDate: 'Provider Review Complete',
            responseCompleteDate: 'Responses Complete',
            cmCodes: 'CM Codes',
            cptWithReimb: 'CPTs with Reimb',
            totalCodes: 'Total Codes',
            cptWithoutReimb: 'CPTs without Reimb.',
            emCodes: 'EM Codes',
            modCodes: 'Mod Codes',
            ascFinancialImpact: 'Reimb. Diff',
            description: 'Description',
            allRecords: 'All Records',
            recordsNotStarted: 'Not Started',
            recordsInProgress: 'In Progress',
            recordsReadyForCoder: 'Ready for Coder',
            recordsCompleted: 'Completed',
            delete: ''
          },
          headingsTooltips: {
            clientName: 'Facility',
            projectName: 'Project Name',
            name: 'Coder',
            identifier: 'Tags',
            identifiers: 'Tags',
            leadName: 'Lead',
            startDate: 'Start Date',
            endDate: 'End Date',
            projectTypeName: 'Project Type',
            projectStatus: 'Project Status',
            coderReviewedDate: 'Coder Review Complete',
            providerReviewedDate: 'Provider Review Complete',
            responseCompleteDate: 'Responses Complete',
            cmCodes: 'CM Codes',
            cptWithReimb: 'CPTs with Reimb',
            totalCodes: 'Total Codes',
            cptWithoutReimb: 'CPTs without Reimb.',
            emCodes: 'EM Codes',
            modCodes: 'Mod Codes',
            ascFinancialImpact: 'Reimb. Diff',
            description: 'Description',
            allRecords: 'All Records',
            recordsNotStarted: 'Not Started',
            recordsInProgress: 'In Progress',
            recordsReadyForCoder: 'Ready for Coder',
            recordsCompleted: 'Completed'
          },
          perPage: 15,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          resizableColumns: false,
          sortIcon: { is: 'none' },
          filterAlgorithm: {
            startDate(row, query) {
              if (row.startDate) {
                return format(parseISO(row.startDate), 'M/dd/yyyy').includes(query)
              }
              return false
            },
            endDate(row, query) {
              if (row.endDate) {
                return format(parseISO(row.endDate), 'M/dd/yyyy').includes(query)
              }
              return false
            }
          }
        },
        dropdownsNeeded: ['projectIdentifiers', 'projectStatus'],
        listloading: false,
        deletePopperContent: ''
      }
    },
    methods: {
      ...mapActions('managedLists/projects/', ['LOAD_PROJECT_LIST', 'DELETE_PROJECT', 'LOAD_OP_ADVANCED_LIST']),
      ...mapActions('dropdowns/', ['GET_DROPDOWNS', 'CONVERT_DROPDOWN_COLUMNFILTERARRAY']),
      ...mapMutations('managedLists/projects/', ['SET_PROJECT_LIST']),
      async deleteProject(row, ref, bool) {
        const popper = this.$refs[ref]
        popper.doDestroy()
        popper.doClose()
        if (!bool) {
          return false
        }
        this.listloading = true
        this.DELETE_PROJECT(row).then(
          response => {
            this.$message({
              message: 'Project Deleted Successfully',
              type: 'info'
            })
            this.LOAD_OP_ADVANCED_LIST({ dateRange: this.GET_DATERANGE(), codeType: this.selectedCodeType, opProjectType: 1004 })
          },
          error => {
            this.$message({
              message: 'Error Deleting Project',
              type: 'error'
            })
            console.log(error)
            this.tableLoader = false
          }
        )
      },
      handleDeletePopover(row) {
        if (row.allRecords > 0) {
          this.deletePopperContent = 'This project has records entered and cannot be deleted, move records first.'
          return
        }
        this.deletePopperContent = 'Delete this Project?'
      },
      canDeleteProject(row) {
        if (row.allRecords > 0) {
          return false
        }
        return true
      },
      setCols() {
        const firstRow = this.projectList.length > 0 ? this.projectList[0] : null
        const newCols = []
        this.displayColumns = JSON.parse(JSON.stringify(this.columns))
        if (firstRow && firstRow.projectTasksJson && this.selectedProcess) {
          const taskItems = JSON.parse(firstRow.projectTasksJson)
          taskItems
            .filter(x => x.ProcessId === this.selectedProcess)
            .forEach(t => {
              newCols.push(t.Name)
              // add tooltip to project task
              this.options.headingsTooltips[t.Name] = t.Name
            })
          const projectStatusIndex = this.displayColumns.findIndex(x => x === 'projectStatus')
          this.displayColumns.splice(projectStatusIndex, 0, ...newCols)
        }

        this.showTable = false
        this.$nextTick(() => {
          this.showTable = true
        })
      },
      handleProjectStatusMultiSelect() {
        // first reset it, this way we can remove filters and restore the original data
        this.SET_PROJECT_LIST(JSON.parse(JSON.stringify(this.projectListBackup)))
        // then filter it
        if (this.projectStatusMultiSelect.length > 0) {
          this.SET_PROJECT_LIST(this.projectList.filter(x => this.projectStatusMultiSelect.some(y => y === x.projectStatus)))
          this.projectStatusMultiSelectBackup = JSON.parse(JSON.stringify(this.projectStatusMultiSelect))
        }
      }
    },
    deactivated: function () {
      this.projectStatusMultiSelect = []
    },
    computed: {
      ...mapState('managedLists/projects/', ['showAddProjectForm', 'dropdownsLoading', 'projectList', 'projectListBackup', 'projectLoading']),
      ...mapState('dropdowns/', ['dropdowns']),
      ...mapGetters('globals/', ['GET_DATERANGE']),
      projectListCalc() {
        return this.projectList
          .filter(x => (this.selectedProcess && x.processId === this.selectedProcess) || !this.selectedProcess)
          .map(p => {
            if (p.projectTasksJson) {
              const projectTasks = JSON.parse(p.projectTasksJson)
              projectTasks
                .filter(t => t.ProcessId === this.selectedProcess)
                .forEach(t => {
                  p[t.Name] = t.TaskDate
                })
            }
            return p
          })
      }
    },
    mounted: function () {
      this.setCols()
    },
    watch: {
      selectedProcess() {
        this.setCols()
      }
    }
  }
</script>

<style scoped>
  >>>.tagsCol {
    max-width: 100px;
    overflow: hidden;
  }

  #reviewListGrid tr td:nth-child(14) {
    width: 3%;
  }

  #reviewListGrid tr td:nth-child(16),
  #reviewListGrid tr td:nth-child(17) {
    width: 1%;
  }

  .wideColumn {
    min-width: 200px;
  }

  >>>.mediumColumn {
    min-width: 160px;
  }

  >>>.VueTables__projectStatus-filter-wrapper input.form-control:first-child {
    display: none;
  }

  .overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 80px;
    text-overflow: ellipsis;
  }

  >>>#reviewListGrid table thead tr:first-child th .VueTables__heading {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* max-height: 80px; */
    text-overflow: ellipsis;
    padding-bottom: 0px;
  }
</style>