<template>
  <div>
    <div v-if="loading" v-loading="loading" element-loading-spinner="atom-audit-loader" style="height: 500px;"></div>
    <div v-if="!loading">
      <FullCalendar class="fullCalendar" ref="fullCalendar" :options="config" v-loading="monthLoading" element-loading-spinner="atom-audit-loader">
        <template v-slot:eventContent="arg">
          <div style="padding: 4px 0px 4px 0px;display: flex; align-items: center; overflow: hidden; border-radius: 50px;">
            <div class="eventTitle">{{ arg.event.title }}</div>
            <template v-if="arg.event.extendedProps.identifiers">
              <span :title="arg.event.extendedProps.identifiers.split(',').join(', ')" style="overflow: hidden;text-overflow: ellipsis;color: #005695;">
                <span class="tagText" v-for="tag in arg.event.extendedProps.identifiers.split(',')" :key="tag"> {{ tag }}</span>
              </span>
            </template>
          </div>
        </template>
      </FullCalendar>
    </div>
    <div class="dialogs">
      <el-popover ref="calendarFiltersPopper" placement="bottom" trigger="manual" title="Filter by:" width="400" @after-enter="handleHidePopOver('calendarFiltersPopper')">
        <el-form size="mini" ref="calendarFiltersForm" label-position="top" label-width="50px">
          <el-form-item size="mini" label="Lead">
            <el-select style="margin: 5px 0px 0px 0px;" size="mini" v-model="leadFilter" clearable filterable multiple placeholder="Lead">
              <el-option :label="'No Lead'" :value="'null'"> </el-option>
              <el-option v-for="item in projectOptionBuilder('leadName', 'text')" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="mini" label="Tags">
            <el-select style="margin: 5px 0px 0px 0px;" size="mini" v-model="tagFilter" clearable filterable multiple placeholder="Tags">
              <el-option :label="'No Tag'" :value="'null'"> </el-option>
              <el-option v-for="item in projectOptionBuilder('identifiers', 'tag')" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="mini" label="Project Type">
            <el-select style="margin: 5px 0px 0px 0px;" size="mini" v-model="projectTypeFilter" clearable filterable multiple placeholder="Project Type">
              <el-option v-for="item in projectOptionBuilder('projectTypeName', 'text')" :key="item.key" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-popover>
      <el-popover ref="calendarProjectDetailsPopper" placement="top" trigger="manual" width="800" title="Project Details" @after-enter="handleHidePopOver('calendarProjectDetailsPopper')">
        <el-form class="projectDetailsForm" size="mini" label-position="left">
          <el-row :gutter="20">
            <el-col :lg="8" :md="12">
              <el-form-item size="mini" label="Project Type">
                {{ currentProjectDetails.projectTypeName }}
              </el-form-item>

              <!-- <el-form-item v-if="isOutpatient && isTrucodeEnabled()" size="mini" label="Outpatient Grouper">
                  <hia-el-select disabled v-model="outpatientGrouperId" placeholder="Outpatient Grouper" :clearable="false" :filterable="true" itemkey="key" label="value"
                    :dropdowns="dropdowns.outpatientGroupers.list"></hia-el-select>
                </el-form-item> -->

              <el-form-item size="mini" label="Project ID">
                {{ currentProjectDetails.projectName }}
              </el-form-item>

              <el-form-item size="mini" label="Project Tags">
                <template v-if="currentProjectDetails.identifiers">
                  <div style="max-width: 100%;margin: 0px 3px 0px 3px;" class="el-tag el-tag--mini el-tag--light" v-for="tag in currentProjectDetails.identifiers.split(',')" :key="tag">
                    <div style="overflow: hidden; text-overflow: ellipsis;" :title="tag">
                      {{ tag }}
                    </div>
                  </div>
                </template>
              </el-form-item>
              <el-form-item size="mini" label="Description">
                {{ currentProjectDetails.description }}
              </el-form-item>
            </el-col>
            <el-col :lg="8" :md="12">
              <el-form-item size="mini" label="Facility">
                {{ currentProjectDetails.clientName }}
              </el-form-item>
              <el-form-item size="mini" label="Start Date">
                {{ currentProjectDetails.startDate ? formatDate(currentProjectDetails.startDate) : '' }}
              </el-form-item>
              <el-form-item size="mini" label="End Date">
                {{ currentProjectDetails.endDate ? formatDate(currentProjectDetails.endDate) : '' }}
              </el-form-item>
              <el-form-item size="mini" label="Project Status">
                {{ currentProjectDetails.projectStatus }}
              </el-form-item>
              <!-- <el-form-item size="mini" label="Project Color">
                  <el-color-picker :disabled="!projectManagement()" size="mini" v-model="projectColor" @change="updateProject(true)"></el-color-picker>
                </el-form-item> -->
            </el-col>
            <el-col :lg="8" :md="12">
              <el-form-item size="mini" label="Created By">
                {{ currentProjectDetails.createdBy }}
              </el-form-item>
              <el-form-item size="mini" label="Lead">
                {{ currentProjectDetails.leadName }}
              </el-form-item>
              <el-form-item v-if="currentProjectDetails.projectTypeName === 'Professional Fee' || currentProjectDetails.projectTypeName === 'Outpatient'" size="mini" label="Default Code Type">
                {{ currentProjectDetails.defaultCodeType }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-button-group style="float: right;">
            <el-button class="hiaButton" plain round size="mini" icon="el-icon-link" @click="viewProject(currentProjectDetails)">View Project</el-button>
          </el-button-group>
        </el-form>
      </el-popover>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import dateHelper from '@/mixins/date-helper'
  import { parse, format } from 'date-fns'
  // import timeGridPlugin from '@fullcalendar/timegrid'
  // import interactionPlugin from '@fullcalendar/interaction'

  export default {
    name: 'projectCalendar',
    mixins: [dateHelper],
    props: {
      dateRange: { type: Array },
      activeTab: { type: String }
    },
    components: {
      FullCalendar
    },
    data: function() {
      return {
        loading: false,
        monthLoading: false,
        localDateRange: [],
        leadFilter: [],
        tagFilter: [],
        projectTypeFilter: [],
        currentProjectDetails: {
          projectTypeName: null,
          projectName: null,
          identifiers: null,
          description: null,
          clientName: null,
          startDate: null,
          endDate: null,
          projectStatus: null,
          createdBy: null,
          leadName: null,
          defaultCodeType: null
        }
      }
    },
    methods: {
      ...mapActions('managedLists/projects/', ['LOAD_CALENDAR_PROJECT_LIST']),
      async initialize() {
        this.localDateRange = this.newDateRange(-45, 45)
        this.loading = true
        try {
          await this.LOAD_CALENDAR_PROJECT_LIST(this.localDateRange)
          this.loading = false
        } catch (e) {
          this.$message({
            message: 'Error Getting Calendar Data',
            type: 'error'
          })
        }
      },
      hexToRGB(hex, alpha) {
        if (hex) {
          const r = parseInt(hex.slice(1, 3), 16)
          const g = parseInt(hex.slice(3, 5), 16)
          const b = parseInt(hex.slice(5, 7), 16)
          return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`
        }
        return ''
      },
      onDateClick(payload) {
        const title = prompt('Please enter a new title for your event')
        if (!title) {
          return
        }
        const id = (this.events.length + 1) * 10
        const { start, end, date, allDay } = payload
        return this.createEvent({
          id,
          title,
          date,
          start,
          end,
          allDay
        })
      },
      onDateSelect(payload) {
        return this.onDateClick(payload)
      },
      onEventClick({ event }) {
        const confirmed = confirm(`Are you sure you want to delete the event '${event.title}'?`)
        if (!confirmed) {
          return
        }
        return this.deleteEvent(event.id)
      },
      onEventDrop({ event }) {
        return this.updateEvent(event)
      },
      handleHidePopOver(ref) {
        const popper = this.$refs[ref]
        const handler = e => {
          if (e.target !== popper.$el) {
            popper.doDestroy()
            popper.doClose()
          }
          document.removeEventListener('click', handler, false)
        }
        document.addEventListener('click', handler, false)
      },
      projectOptionBuilder(property, type) {
        let projectProperties = []
        const projectData = JSON.parse(JSON.stringify(this.calendarProjectList)) || []
        if (projectData.length) {
          const properties = projectData.map(o => o[property])
          if (type === 'tag') {
            projectProperties = [
              ...new Set(
                projectData
                  .filter((v, index) => {
                    return v[property] !== null ? !properties.includes(v[property], index + 1) : false
                  })
                  .map(m => m[property])
                  .join(',')
                  .split(',')
              )
            ].map(m => ({ key: m, value: m }))
          }
          if (type === 'text') {
            projectProperties = projectData
              .filter((v, index) => {
                return v[property] !== null ? !properties.includes(v[property], index + 1) : false
              })
              .map(m => ({ key: m[property], value: m[property] }))
          }
        }
        return projectProperties
      },
      viewProject(arg) {
        this.$router.push(`/projects/${arg.projectID}`)
      },
      toggleProjectDetailsPopover(info, ref) {
        this.currentProjectDetails = JSON.parse(JSON.stringify(info.event.extendedProps.projectDetails))
        const popper = this.$refs[ref]
        popper.doDestroy()
        popper.doClose()
        this.$nextTick(() => {
          const popperClickTarget = info.el.getElementsByClassName('eventTitle')[0] || info.el
          popper.doDestroy()
          popper.referenceElm = popperClickTarget
          this.isOpen = true
          popper.doShow()
        })
      }
    },
    mounted: function() {
      this.initialize()
    },
    computed: {
      ...mapState('managedLists/projects/', ['calendarProjectList']),
      compMinDate() {
        return new Date(this.dateRange[0])
      },
      compMaxDate() {
        return new Date(this.dateRange[1])
      },
      compJoinFilters() {
        let response = []
        const remapLead = this.leadFilter.map(m => {
          return { leadName: m }
        })
        const remapTag = this.tagFilter.map(m => {
          return { identifiers: m }
        })
        const remapProjectType = this.projectTypeFilter.map(m => {
          return { projectTypeName: m }
        })
        const combined = [...remapLead, ...remapTag, ...remapProjectType]
        response = combined
        return response
      },
      compProjectData() {
        let projectData = JSON.parse(JSON.stringify(this.calendarProjectList)) || []

        // run only if project data
        if (projectData.length) {
          // run filters if they exist
          if (this.compJoinFilters.length) {
            projectData = projectData.filter(f => {
              let found = false
              this.compJoinFilters.forEach((v, i) => {
                const key = Object.keys(v)
                if (v[key] === 'null' && f[key] === null) {
                  found = true
                }
                if (f[key] && f[key].includes(v[key])) {
                  found = true
                }
              })
              return found
            })
          }
          // map fulcalendar object
          projectData = projectData.map(m => ({
            id: m.projectID,
            title: m.projectName,
            start: m.startDate,
            end: m.endDate,
            allDay: true,
            textColor: '#ffffff',
            backgroundColor: this.hexToRGB(m.projectColor, '.7'),
            borderColor: m.projectColor,
            identifiers: m.identifiers,
            projectDetails: m
          }))
        }
        return projectData
      },
      // had to make the calendar configuration a computed prop due to the async event data; got this example from the vue vuex repo
      config() {
        return {
          ...this.configOptions,
          ...this.eventHandlers
        }
      },
      configOptions() {
        return {
          height: 'auto', // this forces all projects to show in a day cell; if not the "+N More" link will show
          plugins: [
            dayGridPlugin
            // timeGridPlugin, not used for now
            // interactionPlugin not used for now
          ],
          headerToolbar: {
            left: 'prev,next',
            center: 'title',
            right: 'filtersButton,dayGridMonth,dayGridWeek,dayGridDay'
          },
          // validRange: {
          //   start: this.compMinDate,
          //   end: this.compMaxDate
          // },
          initialView: 'dayGridMonth',
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          events: this.compProjectData,
          weekends: true,
          eventClick: info => {
            this.toggleProjectDetailsPopover(info, 'calendarProjectDetailsPopper')
          },
          datesSet: async e => {
            // listen for button click changes in the calendar and fetch new data if needed
            if (this.localDateRange.length === 0) {
              return
            }

            const dateRangeStart = parse(this.localDateRange[0], 'MM/dd/yyyy', new Date())
            const dateRangeEnd = parse(this.localDateRange[1], 'MM/dd/yyyy', new Date())

            if (dateRangeStart.getTime() <= e.start.getTime() && dateRangeEnd.getTime() >= e.end.getTime()) {
              return
            }

            this.localDateRange = [format(e.start, 'MM/dd/yyyy'), format(e.end, 'MM/dd/yyyy')]
            this.monthLoading = true
            await this.LOAD_CALENDAR_PROJECT_LIST([e.startStr, e.endStr])
            this.monthLoading = false
          },
          views: {
            dayGrid: {
              // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
            },
            timeGrid: {
              // options apply to timeGridWeek and timeGridDay views
            },
            week: {
              // options apply to dayGridWeek and timeGridWeek views
            },
            day: {
              // options apply to dayGridDay and timeGridDay views
            }
          },
          customButtons: {
            filtersButton: {
              text: '',
              hint: 'Filters',
              click: (mouseEvent, e) => {
                if (!e) {
                  return
                }
                const popper = this.$refs.calendarFiltersPopper
                popper.doDestroy()
                popper.doClose()

                this.$nextTick(() => {
                  const popperClickTarget = e
                  popper.doDestroy()
                  popper.referenceElm = popperClickTarget
                  this.isOpen = true
                  popper.doShow()
                })
              }
            }
          }
        }
      },
      eventHandlers() {
        return {
          // dateClick: this.onDateClick,
          // eventClick: this.onEventClick,
          // eventDrop: this.onEventDrop,
          // eventResize: this.onEventDrop,
          // select: this.onDateSelect
        }
      }
    },
    watch: {
      // compMinDate() {
      //   // on daterange change move to new minDate
      //   const calendarApi = this.$refs.fullCalendar.getApi()
      //   calendarApi.gotoDate(this.compMinDate)
      // },
      activeTab() {
        // get calendar api instance and rerender on tab activation.
        const calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.render()
      }
    }
  }
</script>

<style scoped>
  /* the calendar root */
  .fullCalendar .fc {
    max-width: 100%;
    margin: 0 auto;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary,
  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-primary {
    background: #ffffff;
    border: 1px solid #dcdfe6;
    color: #606266;
    padding: 7px 15px 7px 15px;
    font-size: 12px;
    line-height: 1;
    font-weight: 400;
    text-transform: capitalize;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary:focus,
  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-primary:focus {
    box-shadow: 0 0 0 0 rgb(44 62 80 / 25%);
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary:hover,
  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-primary:hover {
    border-color: #005695;
    color: #005695;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary:hover:disabled,
  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-primary:disabled {
    border-color: #dcdfe6;
    color: #606266;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary.fc-prev-button {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 4px 15px 4px 15px;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary.fc-next-button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 4px 15px 4px 15px;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary.fc-dayGridMonth-button {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-group > .fc-button-primary.fc-dayGridDay-button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-button-primary {
    border-radius: 20px;
  }

  .fullCalendar >>> .fc-header-toolbar > .fc-toolbar-chunk > .fc-toolbar-title {
    color: #606266;
  }

  .fullCalendar >>> .fc-daygrid-event.fc-event-start {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .fullCalendar >>> .fc-daygrid-event.fc-event-end {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .fullCalendar >>> .fc-daygrid-event:hover {
    border-color: blue !important;
  }

  .fullCalendar >>> .fc-daygrid-event {
    margin: 0px 0px 3px 0px;
    /* overflow: hidden;
    text-overflow: ellipsis; */
  }

  .fullCalendar >>> .fc-filtersButton-button {
    border-radius: 20px !important;
    position: absolute;
    margin: 0px 0px 0px -50px;
    font-size: 22px !important;
    border: 0px !important;
    padding: 2px 10px 2px 10px !important;
  }

  .fullCalendar >>> .fc-filtersButton-button::before {
    content: '\F0236';
    display: inline-block;
    font: normal normal normal 24px/1 'Material Design Icons';
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
  }

  .fullCalendar >>> .eventTitle {
    margin: 0px 10px 0px 10px; /* text-shadow: 1px 1px 6px rgba(0, 0, 0, 1); */
    letter-spacing: 1px;
  }

  .fullCalendar >>> .el-tag--mini {
    height: 15px;
    line-height: 14px;
    margin-right: 1px;
  }

  .fullCalendar >>> .tagText {
    background-color: #e6eef4;
    color: #005695;
    font-size: 12px;
    border-radius: 4px;
    padding: 0 5px;
    border: 1px solid #ccddea;
    margin-right: 3px;
    white-space: pre;
    line-height: 16px;
  }
</style>

<style>
  .projectDetailsForm .el-form-item__label {
    width: 115px;
  }
  .projectDetailsForm .el-form-item__content {
    font-size: 13px;
    margin-top: 6px;
    margin-left: 115px;
  }
</style>
